<template>
  <div>
    <div>
      <a-form-model
        :model="formInline"
        @submit="handleSubmit"
        @submit.native.prevent
        :colon="colonfalse"
        :rules="rules"
        ref="ruleForm"
      >
        <div class="title">基本信息</div>
        <a-form-model-item label="所属公司" prop="pbcId">
          <a-select
            v-model="formInline.pbcId"
            class="search-expand-value"
            :disabled="type == 'edit' ? true : false"
          >
            <a-select-option
              v-for="opt in filters.GetCompanies"
              :key="opt.bc_id"
              :value="opt.bc_id"
              >{{ opt.bc_name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="父级施工项">
          <!-- <a-input v-model="formInline.pSupId" placeholder=""> </a-input> -->
          <a-select v-model="formInline.psupId" class="search-expand-value">
            <a-select-option
              v-for="opt in filters.ConstructionProjectList"
              :key="opt.pid"
              :value="opt.pid"
              >{{ opt.pname }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="施工项类型">
          <!-- <a-select v-model="formInline.pptId" class="search-expand-value">
            <a-select-option
              v-for="opt in filters.ProjectTypeList"
              :key="opt.ptId"
              :value="opt.ptId"
              >{{ opt.ptName }}</a-select-option
            >
          </a-select> -->
          <a-tree-select
            v-model="formInline.pptId"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="filters.ProjectTypeList"
            tree-default-expand-all
            :replaceFields="{
              key: 'ptId',
              title: 'ptName',
              value: 'ptId',
              children: 'children',
            }"
          >
          </a-tree-select>
        </a-form-model-item>
        <a-form-model-item label="施工项编号">
          <a-tooltip placement="bottom">
            <template slot="title">
              <span>公司自定义</span>
            </template>
            <a-icon type="exclamation-circle" theme="filled" />
          </a-tooltip>
          <a-input v-model="formInline.pnumber" placeholder=""> </a-input>
        </a-form-model-item>
        <a-form-model-item label="施工项名称">
          <a-input v-model="formInline.pname" placeholder=""> </a-input>
        </a-form-model-item>
        <a-form-model-item label="日施工时长(h)">
          <a-input v-model="formInline.pmaxBuildTime" placeholder=""> </a-input>
        </a-form-model-item>
        <a-form-model-item label="工程量计量单位">
          <a-input v-model="formInline.punit" placeholder=""> </a-input>
        </a-form-model-item>
        <a-form-model-item label="销售单价(元)">
          <a-tooltip placement="bottom">
            <template slot="title">
              <span
                >销售单价=包含在施工项的材料费（主、辅材）之和+工人结算单价；材料费=材料单位用量*(1+损耗率)*材料单价。</span
              >
            </template>
            <a-icon type="exclamation-circle" theme="filled" />
          </a-tooltip>
          <a-input v-model="formInline.psalesPrice" placeholder="" disabled>
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="单位施工时间">
          <a-input v-model="formInline.pbuildTime" placeholder=""> </a-input>
        </a-form-model-item>
        <a-form-model-item label="工人岗位">
          <a-select v-model="formInline.pwtId" class="search-expand-value">
            <a-select-option
              v-for="opt in filters.WorkerTypeList"
              :key="opt.type_id"
              :value="opt.type_id"
              >{{ opt.type_name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="工人结算单价(元)">
          <a-input v-model="formInline.pworkPrice" placeholder=""> </a-input>
        </a-form-model-item>
        <div class="title title1">材料信息(选填)</div>
        <div>
          <!-- class="displayflex" -->
          <div>
            <!-- class="width50" -->
            <!-- 多选下拉框 -->
            <a-form-model-item label="辅材" prop="wpCheckway">
              <a-select
                v-model="AuxiliaryMaterial.pmMId"
                class="search-expand-value"
                @change="handleChange_Auxiliary"
                mode="multiple"
              >
                <a-select-option
                  v-for="opt in filters.MaterialList"
                  :key="opt.mId"
                  :value="opt.mId"
                  >{{ opt.mName }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <div class="margin_left">单位辅材量</div>
            <div class="tableWidth">
              <!-- <a-button class="editable-add-btn" @click="handleAdd">
                Add
              </a-button> -->
              <a-table
                bordered
                :data-source="dataSource_Auxiliary"
                :columns="columns_Auxiliary"
                :pagination="false"
              >
                <template slot="pmUnitUsage" slot-scope="text, record">
                  <editable-cell
                    :text="text"
                    @change="
                      onCellChange_Auxiliary(
                        record.mId,
                        'pmUnitUsage',
                        $event,
                        record
                      )
                    "
                  />
                </template>
                <template slot="pmLoss" slot-scope="text, record">
                  <editable-cell
                    :text="text"
                    @change="
                      onCellChange_Auxiliary(
                        record.mId,
                        'pmLoss',
                        $event,
                        record
                      )
                    "
                  />
                </template>
                <template slot="operationTitleIcon">
                  <!-- <span class="sum_now_smokeworkernumFilterIcon"> -->
                  <a-tooltip>
                    <template slot="title"
                      >销售单价包含材料费（主、辅材）+工人结算单价，此处计算费用的材料均为包含在施工项的；材料单位用量表中添加“损耗率”、“包含在施工项”
                    </template>
                    <a-icon type="question-circle" class="operationIcon" />
                  </a-tooltip>
                  <!-- </span> -->
                </template>
                <template slot="operation" slot-scope="text, record">
                  <a-switch
                    v-model="record.pmContain"
                    @change="
                      onChangeSwitchAuxiliary(
                        record.mId,
                        'operation',
                        $event,
                        record
                      )
                    "
                  />
                </template>
              </a-table>
            </div>
          </div>
          <div>
            <!-- 多选下拉框 -->
            <a-form-model-item label="主材" prop="wpCheckway">
              <a-select
                v-model="PrincipalMaterial.pmMId"
                class="search-expand-value"
                @change="handleChange_Principal"
                mode="multiple"
              >
                <a-select-option
                  v-for="opt in filters.MaterialList"
                  :key="opt.mId"
                  :value="opt.mId"
                  >{{ opt.mName }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <div class="margin_left">单位主材量</div>
            <div class="tableWidth">
              <!-- <a-button class="editable-add-btn" @click="handleAdd">
                Add
              </a-button> -->
              <a-table
                bordered
                :data-source="dataSource_Principal"
                :columns="columns_Principal"
                :pagination="false"
              >
                <template slot="pmUnitUsage" slot-scope="text, record">
                  <editable-cell
                    :text="text"
                    @change="
                      onCellChange_Principal(
                        record.mId,
                        'pmUnitUsage',
                        $event,
                        record
                      )
                    "
                  />
                </template>
                <template slot="pmLoss" slot-scope="text, record">
                  <editable-cell
                    :text="text"
                    @change="
                      onCellChange_Principal(
                        record.mId,
                        'pmLoss',
                        $event,
                        record
                      )
                    "
                  />
                </template>
                <template slot="operationTitleIcon">
                  <!-- <span class="sum_now_smokeworkernumFilterIcon"> -->
                  <a-tooltip>
                    <template slot="title"
                      >销售单价包含材料费（主、辅材）+工人结算单价，此处计算费用的材料均为包含在施工项的；材料单位用量表中添加“损耗率”、“包含在施工项”
                    </template>
                    <a-icon type="question-circle" class="operationIcon" />
                  </a-tooltip>
                  <!-- </span> -->
                </template>
                <template slot="operation" slot-scope="text, record">
                  <a-switch
                    v-model="record.pmContain"
                    @change="
                      onChangeSwitchPrincipal(
                        record.mId,
                        'operation',
                        $event,
                        record
                      )
                    "
                  />
                </template>
              </a-table>
            </div>
          </div>
        </div>

        <a-form-model-item :style="{ marginTop: '50px' }">
          <a-button :style="{ marginRight: '18px' }" @click="onClose">
            取消
          </a-button>
          <a-button type="primary" html-type="submit"> 提交 </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { GetCompanies } from "@/api/device";
import { GetWorkerType } from "@/api/worker";
import {
  GetProjectList,
  GetProjectTypeList,
  AddProject,
  UpdateProject,
  GetProjectMaterialList,
  GetMaterialList,
  AddProjectMaterial, //添加施工物料信息
  UpdateProjectMaterial, //批量编辑施工物料信息
} from "@/api/apiJF/template";
const EditableCell = {
  props: {
    text: String,
  },
  data() {
    return {
      value: this.text,
      editable: false,
    };
  },
  methods: {
    handleChange(e) {
      this.value = e.target.value;
      // console.log("handleChange", e, this.value);
    },
    check() {
      this.editable = false;
      this.$emit("change", this.value);
    },
    edit() {
      this.editable = true;
    },
  },
  render(h) {
    // 创建 a-input 和 a-icon 的 VNodes
    const createAInput = () =>
      h("a-input", {
        props: { value: this.value },
        on: {
          change: this.handleChange,
          pressEnter: this.check,
        },
      });

    const createCheckIcon = () =>
      h("a-icon", {
        props: { type: "check" },
        class: "editable-cell-icon-check",
        on: {
          click: this.check,
        },
      });

    const createEditIcon = () =>
      h("a-icon", {
        props: { type: "edit" },
        class: "editable-cell-icon",
        on: {
          click: this.edit,
        },
      });

    // 根据 editable 的值选择渲染的 VNode
    if (this.editable) {
      return h("div", { class: "editable-cell" }, [
        h("div", { class: "editable-cell-input-wrapper" }, [
          createAInput(),
          createCheckIcon(),
        ]),
      ]);
    } else {
      return h("div", { class: "editable-cell" }, [
        h("div", { class: "editable-cell-text-wrapper" }, [
          this.value || " ",
          createEditIcon(),
        ]),
      ]);
    }
  },
};
export default {
  name: "ConstructionProjectEdit",
  components: { EditableCell },
  props: {
    record: {
      type: Object,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      colonfalse: false,
      rules: {
        pBcId: [
          {
            required: true,
            message: "请选择所属公司",
            trigger: "change",
          },
          // {
          //   // min: 1,
          //   // // max: 5,
          //   // message: "分公司不能为空",
          //   // trigger: "blur",
          // },
        ],
      },
      formInline: {
        pbcId: "", //分公司id
      },
      //主材
      PrincipalMaterial: {
        // pmMId: "",
        pmType: 1,
      },
      //辅材
      AuxiliaryMaterial: {
        // pmMId: "",
        pmType: 2,
      },
      formInlineEdit: {
        pbcId: "", //分公司id
        psupId: "",
        pptId: "",
        pnumber: "",
        pname: "",
        pmaxBuildTime: "",
        punit: "",
        psalesPrice: "",
        pbuildTime: "",
        pwpostId: "",
        pworkPrice: "",
        pwtId: "",
      },
      filters: {
        GetCompanies: [],
        ConstructionProjectList: [],
        ProjectTypeList: [],
        WorkerTypeList: [
          {
            type_id: 402,
            type_name: "瓦工工人",
          },
          {
            type_id: 403,
            type_name: "木工工人",
          },
          {
            type_id: 404,
            type_name: "油工工人",
          },
          {
            type_id: 405,
            type_name: "辅助工人",
          },
          {
            type_id: 406,
            type_name: "水电工人",
          },
        ],
        MaterialList: [], //主材
        AuxiliaryMaterial: [],
      },
      dataSource: [
        // {
        //   key: "0",
        //   name: "Edward King 0",
        //   pmUnitUsage: "32",
        // },
        // {
        //   key: "1",
        //   name: "Edward King 1",
        //   pmUnitUsage: "32",
        // },
      ],
      dataSource_Auxiliary: [],
      dataSource_Principal: [],
      count: 2,
      columns_Auxiliary: [
        {
          title: "辅材",
          dataIndex: "mName",
          // width: "30%",
          // scopedSlots: { customRender: "mname" },
        },
        {
          title: "单位用量",
          width: "90px",
          dataIndex: "pmUnitUsage",
          scopedSlots: { customRender: "pmUnitUsage" },
        },
        {
          title: "损耗量%",
          dataIndex: "pmLoss",
          scopedSlots: { customRender: "pmLoss" },
        },
        {
          title: "包含在施工项",
          dataIndex: "operation",
          scopedSlots: {
            customRender: "operation",
            filterIcon: "operationTitleIcon",
          },
          filterDropdown: " ",
        },
      ],
      columns_Principal: [
        {
          title: "主材",
          dataIndex: "mName",
          // width: "30%",
          // scopedSlots: { customRender: "mname" },
        },
        {
          title: "用量",
          width: "90px",
          dataIndex: "pmUnitUsage",
          scopedSlots: { customRender: "pmUnitUsage" },
        },
        {
          title: "损耗量%",
          dataIndex: "pmLoss",
          scopedSlots: { customRender: "pmLoss" },
        },
        {
          title: "包含在施工项",
          dataIndex: "operation",
          scopedSlots: {
            customRender: "operation",
            filterIcon: "operationTitleIcon",
          },
          filterDropdown: " ",
        },
      ],
      editAuxiliaryRecord: {},
      ediPrincipalRecord: {},
      searchprojectMaterialList: {},
      TotalPriceAuxiliary: 0,
      TotalPricePrincipal: 0,
    };
  },
  created() {
    // console.log(this.type, this.record);
    // 获取分公司信息
    GetCompanies().then((res) => {
      this.filters.GetCompanies = res.data;
    });
  },
  mounted() {
    console.log("record", this.record);
    if (this.type == "edit") {
      for (let key in this.formInlineEdit) {
        for (let el in this.record) {
          if (key == el) {
            this.formInlineEdit[key] = this.record[el];
          }
        }
      }
      if (!this.record.psupId) {
        this.formInlineEdit.psupId = 0;
      }
      this.formInlineEdit.pmaxBuildTime =
        this.formInlineEdit.pmaxBuildTime / 3600;
      this.formInlineEdit.pbuildTime =
        Math.round((this.formInlineEdit.pbuildTime / 3600) * 100) / 100;
      this.formInlineEdit.pId = this.record.pid;
      this.formInlineEdit.pid = this.record.pid;
      this.formInline = this.formInlineEdit;
      this.searchprojectMaterialList.pmPId = this.record.pid; //施工项id
      this.searchprojectMaterialList.pmBcIds = this.record.pbcId; //分公司id
      this.GetProjectMaterialList(); //获取主材
      this.GetProjectMaterialList1(); //获取辅材
    }
  },
  watch: {
    "formInline.pbcId": {
      handler(newValue) {
        // console.log("formInline.pbcId", newValue);
        this.GetConstructProjectList(newValue);
        this.GetProjectTypeList(newValue);
        this.GetMaterialList(newValue);
        // this.GetPrincipalMaterialList(newValue);
      },
    },
    // 辅材表格信息
    dataSource_Auxiliary(newValue) {
      // console.log("dataSource_Auxiliary---newValue", newValue);
      this.TotalPriceAuxiliary = 0;
      newValue.forEach((el) => {
        // 如果包含在施工项，则计算到销售单价里
        if (el.pmContain) {
          console.log("el", el);

          el.pmUnitUsage = el.pmUnitUsage * 1; //单位用量
          let pmloss = el.pmLoss * 0.01 + 1;
          // TotalPrice = TotalPrice * 1 + el.price * el.pmUnitUsage;
          // console.log("el", el, pmloss);
          this.TotalPriceAuxiliary =
            this.TotalPriceAuxiliary * 1 + el.price * el.pmUnitUsage * pmloss; //价格*单位用量*（1+损耗率)
          // console.log(
          //   "单位用量",
          //   el.pmUnitUsage,
          //   "pmloss",
          //   pmloss,
          //   "el.price",
          //   el.price
          // );
        }
      });

      // 总金额=主材金额+辅材金额+工人单价
      let psalesPrice =
        this.TotalPriceAuxiliary * 1 +
        this.TotalPricePrincipal * 1 +
        this.formInline.pworkPrice * 1;
      // console.log(
      //   "辅材",
      //   this.TotalPriceAuxiliary,
      //   "主材",
      //   this.TotalPricePrincipal,
      //   "工价",
      //   this.formInline.pworkPrice
      // );
      // this.formInline.psalesPrice = Math.round(psalesPrice * 100) / 100;
      this.formInline.psalesPrice = Math.round(psalesPrice * 100) / 100;
    },
    // 主材表格信息
    dataSource_Principal(newValue) {
      // console.log("dataSource_Principal---newValue", newValue);
      this.TotalPricePrincipal = 0;
      newValue.forEach((el) => {
        // 如果包含在施工项，则计算到销售单价里
        if (el.pmContain) {
          el.pmUnitUsage = el.pmUnitUsage * 1; //单位用量
          if (el.pmloss == null) {
            el.pmloss = 0;
          }
          let pmloss = el.pmLoss * 0.01 + 1;
          // TotalPrice = TotalPrice * 1 + el.price * el.pmUnitUsage;
          // console.log("el", el, pmloss);
          this.TotalPricePrincipal =
            this.TotalPricePrincipal * 1 + el.price * el.pmUnitUsage * pmloss; //价格*单位用量*（1+损耗率)
          // console.log(el.price, el.pmUnitUsage, pmloss);
        }
      });
      // 总金额=主材金额+辅材金额+工人单价
      let psalesPrice =
        this.TotalPriceAuxiliary * 1 +
        this.TotalPricePrincipal * 1 +
        this.formInline.pworkPrice * 1;
      // console.log(
      //   "辅材",
      //   this.TotalPriceAuxiliary,
      //   "主材",
      //   this.TotalPricePrincipal,
      //   "工价",
      //   this.formInline.pworkPrice
      // );

      // this.formInline.psalesPrice = Math.round(psalesPrice * 100) / 100;
      this.formInline.psalesPrice = Math.round(psalesPrice * 100) / 100;
    },
    "formInline.pworkPrice": {
      handler(newValue) {
        if (this.dataSource_Auxiliary.length == 0) {
          this.formInline.psalesPrice = newValue;
        } else {
          // 总金额=主材金额+辅材金额+工人单价
          let psalesPrice =
            this.TotalPriceAuxiliary * 1 +
            this.TotalPricePrincipal * 1 +
            newValue * 1;
          // console.log(
          //   this.TotalPriceAuxiliary,
          //   this.TotalPricePrincipal,
          //   this.formInline.pworkPrice
          // );

          // this.formInline.psalesPrice = Math.round(psalesPrice * 100) / 100;
          this.formInline.psalesPrice = psalesPrice;
        }
      },
    },
  },
  methods: {
    // 获取施工项列表
    GetConstructProjectList(pBcIds) {
      let queryParam = {
        pBcIds,
      };
      let orderParam = ["pBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      this.filters.ConstructionProjectList = [{ pid: 0, pname: "主类目" }];
      return GetProjectList(params).then((res) => {
        // this.filters.ConstructionProjectList = res.data;
        res.data.forEach((el) => {
          this.filters.ConstructionProjectList.push(el);
        });
      });
    },
    // 获取施工项类型列表
    GetProjectTypeList(ptBcIds) {
      let queryParam = {
        ptBcIds,
      };
      let orderParam = ["ptBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetProjectTypeList(params).then((res) => {
        this.filters.ProjectTypeList = res.data;
      });
    },
    // 获取施工材料信息列表
    GetMaterialList(mBcIds) {
      let queryParam = {
        mBcIds,
      };
      // let newObject = {};
      // for (let el in queryParam) {
      //   if (queryParam[el] != "") {
      //     newObject[el] = queryParam[el];
      //   }
      // }
      // let orderParam = Object.keys(newObject);
      let orderParam = ["mBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetMaterialList(params).then((res) => {
        this.filters.MaterialList = res.data;
        // console.log("材料", this.filters.MaterialList);
      });
    },
    // 查询施工物料列表---主材
    GetProjectMaterialList(pmBcIds) {
      let queryParam = this.searchprojectMaterialList;
      queryParam.pmType = "1";
      let orderParam = ["pmPId", "pmBcIds", "pmType"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetProjectMaterialList(params).then((res) => {
        // console.log("主材", res.data);
        // this.PrincipalMaterial.pmMid=
        let pmMId = [];
        res.data.forEach((el) => {
          pmMId.push(el.pmMId);
          // this.PrincipalMaterial.pmMId = el.pmMId;
          this.dataSource_Principal.push({
            mId: el.tmaterial.mId,
            mName: el.tmaterial.mName,
            pmUnitUsage: el.pmUnitUsage + "",
            pmLoss: el.pmLoss,
            pmType: "1",
            pmId: el.pmId,
            price: el.tmaterial.mSalesPrice,
            pmContain: el.pmContain == 1 ? true : false,
          });
        });
        this.PrincipalMaterial.pmMId = pmMId;

        // this.filters.MaterialList = res.data;
        // console.log("主材", this.PrincipalMaterial);
      });
    },
    // 查询施工物料列表---辅材
    GetProjectMaterialList1(pmBcIds) {
      let queryParam = this.searchprojectMaterialList;
      queryParam.pmType = "2";
      let orderParam = ["pmPId", "pmBcIds", "pmType"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetProjectMaterialList(params).then((res) => {
        // this.PrincipalMaterial.pmMid=
        console.log("辅材", res.data);
        let pmMId = [];
        res.data.forEach((el) => {
          pmMId.push(el.pmMId);
          this.dataSource_Auxiliary.push({
            mId: el.tmaterial.mId,
            mName: el.tmaterial.mName,
            pmUnitUsage: el.pmUnitUsage + "",
            pmLoss: el.pmLoss,
            pmType: "2",
            pmId: el.pmId,
            price: el.tmaterial.mSalesPrice,
            pmContain: el.pmContain == 1 ? true : false,
          });
        });

        this.AuxiliaryMaterial.pmMId = pmMId;
      });
    },
    handleSubmit(e) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // alert("submit!");
          // console.log(
          //   "提交",
          //   this.formInline,
          //   this.AuxiliaryMaterial,
          //   this.PrincipalMaterial
          // );
          // pmType   1主材，2辅材
          this.update();
          // this.AddProjectMaterial("17");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 添加或修改
    update() {
      // 主材
      let mainMaterials = [];
      this.dataSource_Principal.forEach((el) => {
        mainMaterials.push({
          // pmPId: pmPId,
          pmMId: el.mId,
          // pmBcId: this.formInline.pbcId,
          pmType: el.pmType * 1,
          pmUnitUsage: el.pmUnitUsage * 1,
          pmLoss: el.pmLoss,
          pmContain: el.pmContain == true ? 1 : 0,
        });
        // list.push(el);
      });
      // 辅材
      let subMaterials = [];
      this.dataSource_Auxiliary.forEach((el) => {
        subMaterials.push({
          // pmPId: pmPId,
          pmMId: el.mId,
          // pmBcId: this.formInline.pbcId,
          pmType: el.pmType * 1,
          pmUnitUsage: el.pmUnitUsage * 1,
          pmLoss: el.pmLoss,
          pmContain: el.pmContain == true ? 1 : 0,
        });
      });
      if (this.type == "add") {
        this.formInline.pmaxBuildTime = this.formInline.pmaxBuildTime * 3600;
        this.formInline.pbuildTime = this.formInline.pbuildTime * 3600;
        let params = this.formInline;
        //  mainMaterials  subMaterials
        // 添加主材辅材
        params.mainMaterials = mainMaterials;
        params.subMaterials = subMaterials;
        console.log("params", params);
        AddProject(params)
          .then((res) => {
            // console.log(res);
            if (res.code == "200") {
              this.$message.success("信息添加成功");
              // this.AddProjectMaterial(res.data);
              this.onClose();
            } else {
              this.$message.error(res.msg);
            }
          })
          .then((res) => {
            // this.AddProjectMaterial()
          });
      } else if (this.type == "edit") {
        this.formInline.pId = this.record.pId;
        this.formInline.pmaxBuildTime = this.formInline.pmaxBuildTime * 3600;
        this.formInline.pbuildTime = this.formInline.pbuildTime * 3600;
        let params = this.formInline;
        // 添加主材辅材
        params.mainMaterials = mainMaterials;
        params.subMaterials = subMaterials;
        UpdateProject(params).then((res) => {
          // console.log(res);
          if (res.code == "200") {
            this.$message.success("修改成功");
            this.onClose();
            // this.UpdateProjectMateria(this.record.pid);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    // // 添加施工物料信息
    // AddProjectMaterial(pmPId) {
    //   let list = [];
    //   this.dataSource_Principal.forEach((el) => {
    //     list.push({
    //       pmPId: pmPId,
    //       pmMId: el.mId,
    //       pmBcId: this.formInline.pbcId,
    //       pmType: el.pmType * 1,
    //       pmUnitUsage: el.pmUnitUsage * 1,
    //       pmLoss: el.pmLoss,
    //       pmContain: el.pmContain == true ? 1 : 0,
    //     });
    //     // list.push(el);
    //   });
    //   this.dataSource_Auxiliary.forEach((el) => {
    //     list.push({
    //       pmPId: pmPId,
    //       pmMId: el.mId,
    //       pmBcId: this.formInline.pbcId,
    //       pmType: el.pmType * 1,
    //       pmUnitUsage: el.pmUnitUsage * 1,
    //       pmLoss: el.pmLoss,
    //       pmContain: el.pmContain == true ? 1 : 0,
    //     });
    //   });
    //   let params = list;
    //   if (params.length > 0) {
    //     AddProjectMaterial(params).then((res) => {
    //       // console.log(res);
    //       if (res.code == "200") {
    //         this.$message.success("材料信息添加成功");
    //         this.onClose();
    //       } else {
    //         this.$message.error(res.msg);
    //       }
    //     });
    //   }
    // },
    // // 修改施工物料信息   pid施工项id
    // UpdateProjectMateria(pmPId) {
    //   let list = [];
    //   this.dataSource_Principal.forEach((el) => {
    //     list.push({
    //       pmPId: pmPId,
    //       pmMId: el.mId,
    //       pmBcId: this.formInline.pbcId,
    //       pmType: el.pmType * 1,
    //       pmUnitUsage: el.pmUnitUsage * 1,
    //       pmLoss: el.pmLoss,
    //       pmId: el.pmId,
    //       pmContain: el.pmContain == true ? 1 : 0,
    //     });
    //     // list.push(el);
    //   });
    //   this.dataSource_Auxiliary.forEach((el) => {
    //     list.push({
    //       pmPId: pmPId,
    //       pmMId: el.mId,
    //       pmBcId: this.formInline.pbcId,
    //       pmType: el.pmType * 1,
    //       pmUnitUsage: el.pmUnitUsage * 1,
    //       pmLoss: el.pmLoss,
    //       pmId: el.pmId,
    //       pmContain: el.pmContain == true ? 1 : 0,
    //     });
    //   });
    //   let params = list;
    //   console.log("list", list);
    //   if (params.length > 0) {
    //     UpdateProjectMaterial(params).then((res) => {
    //       // console.log(res);
    //       if (res.code == "200") {
    //         this.$message.success("材料信息更新成功");
    //         this.onClose();
    //       } else {
    //         this.$message.error(res.msg);
    //       }
    //     });
    //   }
    // },
    // 辅材下拉多选框值改变，表格内容改变
    handleChange_Auxiliary(value) {
      this.$forceUpdate();
      console.log(`selected ${value}`);
      let newValue = value.slice(",");
      // console.log(newValue);
      if (newValue.length > this.dataSource_Auxiliary.length) {
        this.filters.MaterialList.forEach((el) => {
          // newValue[newValue.length - 1].forEach((item) => {});
          if (el.mId == newValue[newValue.length - 1]) {
            // console.log(
            //   "el.mId ",
            //   el,
            //   el.mSalesPrice,
            //   newValue[newValue.length - 1]
            // );
            this.dataSource_Auxiliary.push({
              mId: newValue[newValue.length - 1],
              mName: el.mName,
              pmUnitUsage: "0",
              pmType: "2",
              price: el.mSalesPrice,
              pmContain: false,
              pmLoss: 10,
            });
          }
        });
      } else if (newValue.length < this.dataSource_Auxiliary.length) {
        let dataSource_Auxiliary = [];

        newValue.forEach((el) => {
          this.dataSource_Auxiliary.forEach((item) => {
            if (item.mId == el) {
              dataSource_Auxiliary.push(item);
            }
          });
        });
        this.dataSource_Auxiliary = dataSource_Auxiliary;
        console.log("this.dataSource_Auxiliary", this.dataSource_Auxiliary);
      }
      // this.dataSource_Auxiliary = [];

      // console.log("辅材下拉框改变", this.dataSource_Auxiliary);

      // this.dataSource = dataSourceList;
    },
    // 主材下拉多选框值改变，表格内容改变
    handleChange_Principal(value) {
      let newValue = value.slice(",");
      console.log("主材下拉多选框值改变", newValue);
      if (newValue.length > this.dataSource_Principal.length) {
        this.filters.MaterialList.forEach((el) => {
          // newValue[newValue.length - 1].forEach((item) => {});
          if (el.mId == newValue[newValue.length - 1]) {
            this.dataSource_Principal.push({
              mId: newValue[newValue.length - 1],
              mName: el.mName,
              pmUnitUsage: "0",
              pmType: "1",
              price: el.mSalesPrice,
              pmContain: false,
              pmLoss: 10,
            });
          }
        });
      } else if (newValue.length < this.dataSource_Principal.length) {
        let dataSource_Principal = [];
        newValue.forEach((el) => {
          this.dataSource_Principal.forEach((item) => {
            if (item.mId == el) {
              dataSource_Principal.push(item);
            }
          });
        });
        this.dataSource_Principal = dataSource_Principal;
        console.log("this.dataSource_Principal", this.dataSource_Principal);
      }
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 关闭弹出框
    onClose() {
      this.$emit("onClose");
    },
    // 辅材表格值修改
    onCellChange_Auxiliary(mId, dataIndex, value, record) {
      // console.log(mId, dataIndex, value, record);
      this.editAuxiliaryRecord = record;

      const dataSource_Auxiliary = [...this.dataSource_Auxiliary];
      // const target = dataSource_Auxiliary.find((item) => item.key === key);
      const target = dataSource_Auxiliary.find((item) => item.mId === mId);

      if (target) {
        target[dataIndex] = value;
        this.dataSource_Auxiliary = dataSource_Auxiliary;
      }
    },
    // 删除表格信息
    onDelete_Auxiliary(mId) {
      const dataSource_Auxiliary = [...this.dataSource_Auxiliary];
      // this.dataSource_Auxiliary = dataSource_Auxiliary.filter((item) => item.key !== key);
      this.dataSource_Auxiliary = dataSource_Auxiliary.filter(
        (item) => item.mId !== mId
      );
    },
    // 主材表格值修改
    onCellChange_Principal(mId, dataIndex, value, record) {
      console.log(mId, dataIndex, value, record);
      this.editPrincipalRecord = record;
      const dataSource_Principal = [...this.dataSource_Principal];
      // const target = dataSource_Principal.find((item) => item.key === key);
      const target = dataSource_Principal.find((item) => item.mId === mId);

      if (target) {
        target[dataIndex] = value;
        this.dataSource_Principal = dataSource_Principal;
      }
    },
    onDelete_Principal(mId) {
      const dataSource_Principal = [...this.dataSource_Principal];
      // this.dataSource_Principal = dataSource_Principal.filter((item) => item.key !== key);
      this.dataSource_Principal = dataSource_Principal.filter(
        (item) => item.mId !== mId
      );
    },
    handleAdd() {
      const { count, dataSource_Auxiliary } = this;
      const newData = {
        key: count,
        name: `Edward King ${count}`,
        age: 32,
        address: `London, Park Lane no. ${count}`,
      };
      this.dataSource_Auxiliary = [...dataSource_Auxiliary, newData];
      this.count = count + 1;
    },
    onChangeSwitchAuxiliary(mId, dataIndex, value, record) {
      // console.log(`a-switch to ${checked}`);
      // console.log(mId, dataIndex, value, record);
      const dataSource_Auxiliary = [...this.dataSource_Auxiliary];
      // const target = dataSource_Auxiliary.find((item) => item.key === key);
      const target = dataSource_Auxiliary.find((item) => item.mId === mId);

      if (target) {
        target[dataIndex] = value;
        this.dataSource_Auxiliary = dataSource_Auxiliary;
      }
    },
    onChangeSwitchPrincipal(mId, dataIndex, value, record) {
      // console.log(`a-switch to ${checked}`);
      // console.log(mId, dataIndex, value, record);
      const dataSource_Principal = [...this.dataSource_Principal];
      // const target = dataSource_Principal.find((item) => item.key === key);
      const target = dataSource_Principal.find((item) => item.mId === mId);
      if (target) {
        target[dataIndex] = value;
        this.dataSource_Principal = dataSource_Principal;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  width: 100%;
  text-align: left;
  padding-left: 30px;
  margin: 20px 0;
  font-weight: 800;
  font-size: 16px;
}

.title1 {
  margin-top: 50px;
}
/deep/.ant-row {
  display: flex;
  width: 90%;
}
/deep/ .ant-form-item-control-wrapper {
  width: 80%;
}
.wpCheckOwnerName {
  /deep/ .ant-form-item-control-wrapper {
    text-align: left;
  }
}
/deep/ .ant-form-item-label {
  width: 200px;
}
/deep/ .ant-form {
  text-align: center;
}
.displayflex {
  display: flex;
  /deep/ .ant-form-item-control-wrapper {
    width: 45%;
  }
}
/deep/ .ant-form-item-children {
  display: flex;
  // text-align: center;
  // justify-content: center;
  align-items: center;
  justify-content: right;
}
.displayflex {
  display: flex;
  .width50 {
    width: 50%;
    text-align: left;
    // /deep/ .ant-form-item-label {
    //   // background-color: pink;
    //   // text-align: left;
    //   // width: 10%;
    // }
  }
}
.margin_left {
  margin-left: 14%;
  // background-color: pink;
  text-align: left;
  font-weight: 600;
}
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
.tableWidth {
  width: 70%;
  // margin-left: 16%;
  margin: 20px 0;
  margin-left: 100px;
  // margin-left: 30%;
}
/deep/.ant-table-thead > tr > th .ant-table-filter-icon {
  color: #000;
  font-size: 15px;
}
</style>